<template>
    <div class="cylinder">
        <dv-border-box-10 :color="['rgba(110,52,228, .1)', '#6E34E4']" backgroundColor="rgba(110,52,228, .01)" style="font-size: 12px; width: 100%; height: 214px;">
            <div>
                <div class="total-title">
                    {{ statisticsData.title }}
                </div>
                <div class="cylinder-content" v-if="statisticsData.data.length > 0">
                    <div class="content-left">
                        <p class="top-font">{{ statisticsData.data[0].count }}</p>
                        <van-image
                            class="status-img"
                            :src="require('@/assets/images/cylinder-red.png')"
                        />
                        <p class="bottom-title">{{ statisticsData.data[0].title }}</p>
                    </div>
                    <div class="content-right">
                        <p class="top-font">{{ statisticsData.data[1].count }}</p>
                        <van-image
                            class="status-img"
                            :src="require('@/assets/images/cylinder-blue.png')"
                        />
                        <p class="bottom-title">{{ statisticsData.data[1].title }}</p>
                    </div>
                </div>
                <div class="empty" v-else>
                    暂未开展
                </div>
            </div>
        </dv-border-box-10>
    </div>
</template>
<script>
export default {
    props: {
        statisticsData: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            list: ''
        }
    }
}
</script>
<style lang="scss" scoped>
.cylinder {
    padding: 0px 10px 15px 10px;
    p {
        margin: 0;
        padding: 0;
    }
    .total-title {
        padding: 16px;
        font-size: 14px;
        color: #885CE9;
     }
    .cylinder-content {
        .content-left, .content-right {
            display: inline-block;
            width: 50%;
            text-align: center;
            .top-font {
                color: #885CE9;
            }
        }
    }
    .empty {
        text-align: center;
        line-height: 130px;
        font-size: 12px;
        letter-spacing: 5px;
    }
}
</style>
