<template>
    <div class="good-garage">
        <Cylinder :statisticsData="carInsurance" />
        <Cylinder :statisticsData="noCar" />
    </div>
</template>
<script>
import Cylinder from './cylinder.vue'
import { request } from "@/api/service";
export default {
    components: { Cylinder },
    data () {
        return {
            list: '',
            carInsurance: {
                title: '车险',
                data: []
            },
            noCar: {
                title: '非车',
                data: []
            },
            compareData: {
                'YZB_TOTAL': '优质保总单量(单)',
                'RVI_TOTAL': 'RVI总单量(单)'
            }
        }
    },
    created () {
        // 获取非车数据
        request({
            url: `afis-carservice/statistics/battleReportContract`,
            method: "post",
            data: {}
        }).then(res => {
            let noCarData = res
            noCarData.map(item => {
                item.title = this.compareData[item.type]
            })
            this.noCar.data = noCarData
        })

    }
}
</script>
<style lang="scss" scoped>

</style>
