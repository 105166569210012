<template>
    <div class="paic-rent">
        <Cylinder v-for="(item, ind) in initData" :key="ind" :statisticsData="item" />
    </div>
</template>
<script>
import Cylinder from './cylinder.vue'
import { request } from "@/api/service";
export default {
    components: { Cylinder },
    data () {
        return {
            carInsurance: {
                title: '平安租赁',
                data: []
            },
            initData: [],
            compareData: {
                'EXTENDED_WARRANTY': '延保',
                'TRAVEL': '代步'
            }
        }
    },
    created () {
        // 获取平安租赁
        request({
            url: `afis-pingan-lease/policy/paLeaseStatistics/battleReport`,
            method: "post",
            data: {}
        }).then(res => {
            res.map(item => {
                item.title = this.compareData[item.type]
                item.data = [{}, {}]
                item.data[0].count = item.premium
                item.data[0].title = '保费金额(万元)'
                item.data[1].count = item.count
                item.data[1].title = '销售数量(单)'
            })
            this.initData = res
        })
    }
}
</script>
<style lang="scss" scoped>

</style>
