<template>
    <div class="car-insurance">
        <!-- 车险出单量 -->
        <dv-border-box-10 :color="['rgba(110,52,228, .1)', '#6E34E4']" backgroundColor="rgba(110,52,228, .01)" style="margin-top: -10px; font-size: initial; padding-bottom: 6px; height: 170px;">
            <div class="insurance-num">
                <div class="top-des">
                    <span class="font">
                        保险出单量（单）
                    </span>
                    <span class="order-num">
                        {{ totalData.quantityTotal }}
                    </span>
                </div>
                <div class="bold-line"></div>
            </div>
            <div id="orderNumChart" :style="{width: '95%', height: '150px'}"></div>
        </dv-border-box-10>
        <!-- 总保费 -->
        <dv-border-box-10 :color="['rgba(110,52,228, .1)', '#6E34E4']" backgroundColor="rgba(110,52,228, .01)" style="margin-top: 15px; font-size: initial; padding-bottom: 6px; height: 170px;">
            <div class="insurance-num">
                <div class="top-des">
                    <span class="font">
                        总保费金额（万元）
                    </span>
                    <span class="order-num">
                        {{ totalData.premiumTotal }}
                    </span>
                </div>
                <div class="bold-line"></div>
            </div>
            <div id="totalAmount" :style="{width: '95%', height: '150px'}"></div>
        </dv-border-box-10>
        <!-- 各个保险公司 -->
        <dv-border-box-10 :color="['rgba(110,52,228, .1)', '#6E34E4']" backgroundColor="rgba(110,52,228, .01)" id="companyBox" style="margin-top: 15px; font-size: initial; padding-bottom: 6px; overflow: hidden;">
            <div id="companyTotal" :style="{width: '95%'}"></div>
        </dv-border-box-10>
    </div>
</template>
<script>
import * as echarts from 'echarts/lib/echarts'
import { request } from "@/api/service";
import { cloneDeep } from 'lodash'
let this_ = null
export default {
    data () {
        return {
            autoHeight: 0,
            orderNumSeries: {
                title: {
                    text: ''
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    top: '0%',
                    containLabel: false
                },
                xAxis: {
                    type: 'log',
                    show: false,
                    boundaryGap: ['20', '20']
                },
                yAxis: {
                    show: false,
                    type: 'category'
                },
                series: [
                    {
                        name: '非车出单',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 9,
                            formatter: function(params) {//核心部分 formatter 可以为字符串也可以是回调 
                    　　　　　　return this_.orderNumCompare.ONLINE_POLICY_COUNT
                    　　　　},
                        },
                        barGap: '100%',
                        barMinHeight: 2
                    },
                    {
                        name: '保单补录',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 9,
                            formatter: function(params) {//核心部分 formatter 可以为字符串也可以是回调 
                    　　　　　　return this_.orderNumCompare.OFFLINE_POLICY_COUNT
                    　　　　}
                        },
                        barMinHeight: 2
                    },
                    {
                        name: '车险保单',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 9,
                            formatter: function(params) {//核心部分 formatter 可以为字符串也可以是回调 
                    　　　　　　return this_.orderNumCompare.SYN_POLICY_COUNT
                    　　　　}
                        },
                        barMinHeight: 2
                    }
                ],
                legend: {
                    orient: 'horizontal',
                    x: 'right',
                    y: '86px',
                    selectedMode: false
                }
            },
            totalAmountObj: {
                title: {
                    text: ''
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    top: '0%',
                    containLabel: false
                },
                xAxis: {
                    type: 'log',
                    show: false,
                    boundaryGap: ['20', '20']
                },
                yAxis: {
                    show: false,
                    type: 'category'
                },
                series: [
                    {
                        name: '非车出单',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 9,
                            formatter: function(params) {//核心部分 formatter 可以为字符串也可以是回调 
                    　　　　　　return this_.orderNumCompare.ONLINE_POLICY_PREMIUM
                    　　　　},
                        },
                        barGap: '100%',
                        barMinHeight: 2
                    },
                    {
                        name: '保单补录',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 9,
                            formatter: function(params) {//核心部分 formatter 可以为字符串也可以是回调 
                    　　　　　　return this_.orderNumCompare.OFFLINE_POLICY_PREMIUM
                    　　　　},
                        },
                        barMinHeight: 2
                    },
                    {
                        name: '车险保单',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            fontSize: 9,
                            formatter: function(params) {//核心部分 formatter 可以为字符串也可以是回调 
                    　　　　　　return this_.orderNumCompare.SYN_POLICY_PREMIUM
                    　　　　},
                        },
                        barMinHeight: 2
                    },
                ],
                legend: {
                    orient: 'horizontal',
                    x: 'right',
                    y: '86px',
                    selectedMode: false
                }
            },
            companyTotalObj: {
                title: {
                    text: ''
                },
                grid: {
                    left: '3%',
                    top: 50,
                    width: '80%',
                    containLabel: true
                },
                xAxis: {
                    type: 'log',
                    show: false,
                    boundaryGap: ['20', '20']
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: '10px',
                        showMinLabel: true,
                        boundaryGap: ['20', '20']
                    }
                },
                series: [
                    {
                        name: '保费金额',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            formatter: function(params) {//核心部分 formatter 可以为字符串也可以是回调 
                    　　　　　　if(params.value){//如果当前值存在则拼接
                    　　　　　　　　return this_.comparePremium[params.name] + '万' 
                    　　　　　　}else{//否则返回个空
                    　　　　　　　　return '';
                    　　　　　　}
                    　　　　},
                           fontSize: 9
                        },
                        barMinHeight: 2
                    },
                    {
                        name: '出单量',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            formatter: function(params) {//核心部分 formatter 可以为字符串也可以是回调 
                    　　　　　　if(params.value){//如果当前值存在则拼接
                    　　　　　　　　return params.value
                    　　　　　　}else{//否则返回个空
                    　　　　　　　　return '';
                    　　　　　　}
                    　　　　},
                           fontSize: 9
                        },
                        barMinHeight: 2,
                        // barWidth: 10,
                        // barCategoryGap: 10
                    }
                ],
                legend: {
                    orient: 'horizontal',
                    x: 'right',
                    top: 20,
                    selectedMode: false
                },
                categoryAxis: {
                    axisLabel: {
                        show: false
                    }
                },
            },
            totalData: {
                quantityTotal: null,
                premiumTotal: null
            },
            lessOne: [],
            greaterThousand: [],
            greaterWan: [],
            greaterShiWan: [],
            comparePremium: {},
            orderNumCompare: {
                ONLINE_POLICY_COUNT: '',
                OFFLINE_POLICY_COUNT: '',
                SYN_POLICY_COUNT: ''
            },
            amountCompare: {
                OFFLINE_POLICY_PREMIUM: '',
                SYN_POLICY_PREMIUM: '',
                ONLINE_POLICY_PREMIUM: ''
            }
        }
    },
    mounted () {
        this_ = this
        this.drawLine()
    },
    methods: {
        drawLine () {
            request({
                url: `afis-renewal/autoPolicyStatistics/battleReportPolicy`,
                method: "post",
                data: {}
            }).then(res => {
                let orderNumObj = res
                let copyData = cloneDeep(orderNumObj.quantityStatistics)
                copyData.map(item => {
                    this.orderNumCompare[item.type] = item.count
                })
                // 车险出单量
                orderNumObj.quantityStatistics.map(item => {
                    item.count = !item.count ? 1 : item.count
                    // 报价出单
                    if (item.type === 'ONLINE_POLICY_COUNT') {
                        this.orderNumSeries.series[0].data = [+item.count]
                    }
                    // 保单补录
                    if (item.type === 'OFFLINE_POLICY_COUNT') {
                        this.orderNumSeries.series[1].data = [+item.count]
                    }
                    // 保单回传
                    if (item.type === 'SYN_POLICY_COUNT') {
                        this.orderNumSeries.series[2].data = [+item.count]
                    }
                    // 总量
                    if (item.type === 'TOTAL') {
                        this.totalData.quantityTotal = item.count
                    }
                })
                // 总保费金额
                // amountCompare
                let copyAmountData = cloneDeep(orderNumObj.premiumStatistics)
                copyAmountData.map(item => {
                    this.orderNumCompare[item.type] = item.premium
                })
                orderNumObj.premiumStatistics.map(item => {
                    item.premium = !item.premium ? 1 : item.premium
                    // 报价出单
                    if (item.type === 'ONLINE_POLICY_PREMIUM') {
                        this.totalAmountObj.series[0].data = [+item.premium]
                    }
                    // 保单补录
                    if (item.type === 'OFFLINE_POLICY_PREMIUM') {
                        this.totalAmountObj.series[1].data = [+item.premium]
                    }
                    // 保单回传
                    if (item.type === 'SYN_POLICY_PREMIUM') {
                        this.totalAmountObj.series[2].data = [+item.premium]
                    }
                    // 总量
                    if (item.type === 'TOTAL') {
                        this.totalData.premiumTotal = item.premium
                    }
                })

                // 基于准备好的dom，初始化echarts实例
                let orderNumChart = echarts.init(document.getElementById('orderNumChart'))
                // 绘制图表
                orderNumChart.setOption(this.orderNumSeries);

                // 基于准备好的dom，初始化echarts实例
                let totalAmount = echarts.init(document.getElementById('totalAmount'))
                // 绘制图表
                totalAmount.setOption(this.totalAmountObj);
            })
        
            
            request({
                url: `afis-renewal/autoPolicyStatistics/battleReportPolicyByInsureOrgCode`,
                method: "post",
                data: {}
            }).then(res => {
                // 基于准备好的dom，初始化echarts实例
                let companyTotal = echarts.init(document.getElementById('companyTotal'))
                // 绘制图表
                res.reverse()
                let companyData = res
                companyData.map(item => {
                    item.orgName = item.orgName === null ? item.insuredOrgCode : item.orgName
                    this.comparePremium[item.orgName] = item.premium
                    if (item.premium < 1 || item.premium === 1 ) {
                        item.premium = 1
                    }
                    this.companyTotalObj.series[0].data.push((item.premium))
                    this.companyTotalObj.series[1].data.push(item.count)
                    this.companyTotalObj.yAxis.data.push(item.orgName)
                })
                companyTotal.setOption(this.companyTotalObj);
                this.autoHeight = companyData.length * 2 * 18; // counst.length为柱状图的条数，即数据长度。35为我给每个柱状图的高度，50为柱状图x轴内容的高度(大概的)。
                companyBox.style.height = (this.autoHeight) + 'px'
                companyTotal.resize({height:(this.autoHeight + 80)});
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.car-insurance {
    padding: 10px;
    .insurance-num {
        padding: 10px;
        padding-top: 20px;
        position: relative;
        .top-des {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #885CE9;
            padding-bottom: 4px;
            span {
                display: inline-block;
                color: #885CE9;
            }
        }
        .bold-line {
            width: 100px;
            border-bottom: 4px solid #885CE9;
            position: absolute;
            right: 10px;
        }
    }
}
</style>
