<template>
    <div class="war" id="war">
        <div class="war-report">
            <div class="report-total">
                <p class="report-title">
                    入驻租户
                </p>
                <div class="total-img">
                    <span class="font-bac">{{ totalNumForm.TENANT_TOTAL }}</span>
                </div>
            </div>
            <div class="report-total">
                <p class="report-title">
                    经销商
                </p>
                <div class="total-img">
                    <span class="font-bac">{{ totalNumForm.DEALER_COUNT }}</span>
                </div>
            </div>
            <div class="report-total">
                <p class="report-title">
                    合作渠道
                </p>
                <div class="total-img">
                    <span class="font-bac">{{ totalNumForm.CHANNEL_TOTAL }}</span>
                </div>
            </div>
        </div>
        <div class="report-type">
            <div class="report-tab">
                <div class="war-tab-item" :class="{'activeItem': activeIndex === ind}" v-for="(item, ind) in tabs" :key="ind" @click="activeIndex = ind">
                    <span class="dot"></span>
                    <span class="tab-title">{{ item }}</span>
                </div>
            </div>
            <div class="report-tab-conent">
                <CarInsurance v-if="activeIndex === 0" />
                <GoodGarage v-if="activeIndex === 1" />
                <PaicRent v-if="activeIndex === 2" />
            </div>
        </div>
    </div>
</template>
<script>
import { request } from "@/api/service";
import CarInsurance from './tabComponents/carInsurance.vue'
import GoodGarage from './tabComponents/goodGarage.vue'
import PaicRent from './tabComponents/paicRent.vue'
import { isWxAuthorize } from '@/utils/weixin'
import { Dialog } from "vant";
import { mapState } from "vuex";
export default {
    components: { CarInsurance, GoodGarage, PaicRent },
    data () {
        return {
            activeIndex: 0,
            // tabs: ['车险', '优车库', '平安租赁'],
            tabs: ['保险'],
            totalNumForm: {
                "DEALER_COUNT": 1, // 经销店
                "TENANT_TOTAL": 2, // 租户
                "CHANNEL_TOTAL": 3 // 渠道
            }
        }
    },
    async created () {
        this.$nextTick(() => {
            if (this.currentMenus.length > 0 && !this.currentMenus.includes('/warReport')) {
                Dialog({ message: '当前用户暂时没有该页面权限' });
                this.$router.push('/mpArriveVehicleRecord')
                return false
            }
        })
        await isWxAuthorize()
        this.init()
    },
    mounted () {
        war.style.height = (document.documentElement.clientHeight - 60) + 'px'
    },
    computed: {
        ...mapState("user", ["currentMenus"])
    },
    methods: {
        init () {
            // 租户与渠道的统计
            request({
                url: `afis-auth/auth/statistics/battleReportTenant`,
                method: "post",
                data: {}
            }).then(res => {
                this.totalNumForm.DEALER_COUNT = res.count
                res.map(item => {
                    this.totalNumForm[item.type] = item.count
                })
                // 经销店数量统计
                request({
                    url: `organization/statistics/battleReportDealer`,
                    method: "post",
                    data: {}
                }).then(res => {
                    this.totalNumForm.DEALER_COUNT = res.count
                })
            })
        }   
    }
}
</script>
<style lang="scss" scoped>
.war {
    overflow: auto;
    .war-report {
        display: flex;
        justify-content: space-between;
        width: 95%;
        margin: 0 auto;
        text-align: center;
        margin-top: 2px;
        p {
            margin: 0;
            padding: 0;
        }
        .report-total {
            width: 32%;
            height: 135px;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: rgb(255, 255, 255);
            border: 2px solid rgb(51, 255, 255);
        }
        .report-title {
            font-size: 14px;
            font-weight: bold;
            padding-left: 8px;
            padding-top: 4px;
            text-align: left;
        }
        .total-img {
            height: 90px;
            width: 82px;
            background-image: url('../../assets/images/test.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            display: inline-block;
            font-size: 14px;
            color: #fff;
            text-align: center;
            line-height: 94px;
            font-weight: bold;
            margin-top: 10px;
        }
    }
    .report-type {
        font-size: initial;
        margin-top: 15px;
        .report-tab {
            display: flex;
            justify-content: space-around;
            margin-bottom: 15px;
            .war-tab-item {
                width: 28%;
                height: 30px;
                line-height: 28px;
                border: 1px solid #AEAEAE;
                border-radius: 30px;
                font-size: 12px;
                line-height: 31px;
                .dot {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #AEAEAE;
                    margin-left: 10px;
                    margin-right: 10px;
                }
                .tab-title {
                    display: inline-block;
                    width: 50%;
                    text-align-last: justify;
                }
            }
            .activeItem {
                border: 1px solid #6293FF;
                .dot {
                    background: #6293FF;
                }
                .tab-title {
                    color: #6293FF;
                }
            }
        }
    }
    .font-bac {
        background: #003C97;
    }
}
</style>